<template>
  <div>
    <div class="c3" ref="graph"></div>
  </div>
</template>

<script>
import c3 from 'c3';
import 'c3/c3.css';

export default {
  name: 'C3Graph',
  props: {
    data: Object,
    config: Object,
    height: Number,
    width: Number
  },
  mounted() {
    if (this.data) {
      this.generateGraph();
    }

  },
  methods: {
    generateGraph() {
      const graph = c3.generate({
        bindto: this.$refs.graph,
        data: this.data,
        zoom: {
          enabled: true
        },
        size: {
          height: this.height,
          width: this.width
        },
        padding: {
          top: 20,
          right: 0,
          bottom: 20,
          left: 40
        },

        ...this.config
      });
      this.$emit('graph-generated', graph);
    }
  }
};
</script>

<style>
  .c3 {
    background-color: #fff !important;
  }
</style>
